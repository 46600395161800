import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["email"]

  connect() {
    const options = {
      root: null, // sets the framing element to the viewport
      rootMargin: '0px',
      threshold: 1
    };
    document.addEventListener("DOMContentLoaded", () => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setTimeout(() => {
              entry.target.style.opacity = 1;
              entry.target.style.visibility = 'visible';
              entry.target.classList.add('scroll-to-element-visible');
            }, 200);
          } else {
            entry.target.style.opacity = 0;
            entry.target.style.visibility = 'hidden';
            entry.target.classList.remove('scroll-to-element-visible');
          }
        });
      });

      // Observe all elements with the class 'hidden-element'
      document.querySelectorAll('.scroll-to-element').forEach((el) => observer.observe(el));
    }, options);

    document.addEventListener('scroll', function() {
        var divs = document.querySelectorAll('.animatedDiv');

        divs.forEach(div => {
            var position = div.getBoundingClientRect();

            // Checking if each div is visible
            if(position.top < window.innerHeight && position.bottom >= 0) {
                div.classList.add('animate-margin');
            } else {
                div.classList.remove('animate-margin');
            }
        });
    });
  }

  submitConversion() {
    const emailValue = this.emailTarget.value;
    if (emailValue != 'lorah9712@gmail.com') {
      gtag_report_conversion();
    }
  }
}
